import React from 'react';
import styled from 'styled-components';
import useInfoByHost from '../hooks/useInfoByHost';
import background_header from '../images/phone_bottom_bg.png';
import background_footer from '../images/yidongduanbeijingtu.png';
import comp1 from '../images/comp1.png';
import comp2 from '../images/comp2.png';
import comp3 from '../images/comp3.png';
import page from '../images/page.png';
import fazhan from '../images/fazhan.png';
import jiaoyu from '../images/jiaoyu.png';
import keyan from '../images/keyan.png';
import shifan from '../images/shifan.png';
import beida from '../images/beida.png';

export default function App() {
  const { headerLeftLogo, dongtu } = useInfoByHost();

  return (
    <React.Fragment>
      <BgImg>
        <img alt='' className='header' src={headerLeftLogo} />
        <Title>
          <div className='title'>数据赋能教师发展</div>
          <div className='introduce'>
            教研、培训、科研一体化研修系统解决方案
          </div>
          {/* <Button className="down-load">APP下载</Button> */}
        </Title>
        <Gif>
          <div className='gif-wrapper'>
            <img alt='' className='gif' src={dongtu} />
          </div>
        </Gif>
      </BgImg>
      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe67c;</span>学校教师<b>发展仪表盘</b>
          </div>
          <div className='en'>Development Dashboard</div>
          <div className='content'>
            十一个维度，动态展示学校教师队伍发展。
            <br />
            研修活动、课题研究、教师学习一目了然。
          </div>
        </div>
        <div className='bottom'>
          <div className='circle'></div>
          <img alt='' className='img' src={comp1} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe68e;</span>
            <b>教研成果展示</b>
          </div>
          <div className='en'>Achievement Exhibition</div>
          <div className='content'>
            教研组、名师工作室研修成果个性化展示。
            <br />
            活动时间线、成员成果、资源建设、课题研究、特色工作系统展示。
          </div>
        </div>
        <div className='bottom'>
          <img alt='' className='comp2' src={page} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe683;</span>
            <b>智慧评课</b>让教研更有效
          </div>
          <div className='en'>Intelligent Classroom Observation</div>
          <div className='content'>
            支持教研评课活动结构化落地，让教研评课过程数据被记录。
            <br />
            教师课堂变化动态跟踪，学校课堂变化量化比较。
          </div>
        </div>
        <div className='bottom2'>
          <img alt='' className='comp3' src={comp3} />
        </div>
      </Item>

      <Item>
        <div className='top'>
          <div className='title'>
            <span className='iconfont'>&#xe690;</span>
            <b>资源共建</b>凸显教师贡献
          </div>
          <div className='en'>Resources Co-construction</div>
          <div className='content'>
            汇集校内教学资源，凸显教师贡献。
            <br />
            校际资源共享，打造“名师课堂”。
          </div>
        </div>
        <div className='bottom2'>
          <div className='block'></div>
          <img alt='' className='page' src={comp2} />
        </div>
      </Item>

      <ImgList>
        <div className='title'>合作单位</div>
        <div className='imgs'>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={keyan}
              onClick={() => window.open(' http://www.bjesr.cn/')}
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={shifan}
              onClick={() => window.open(' https://www.bnu.edu.cn/')}
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={jiaoyu}
              onClick={() => window.open('https://www.bjie.ac.cn/')}
            />
          </div>
        </div>
        <div className='imgs'>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={fazhan}
              onClick={() =>
                window.open(' http://www.cseds.edu.cn/edoas2/zlxh/index.jsp')
              }
            />
          </div>
          <div className='img-wrapper'>
            <img
              alt=''
              className='img'
              src={beida}
              onClick={() => window.open('http://www.gse.pku.edu.cn/')}
            ></img>
          </div>
        </div>
      </ImgList>

      <BgImgBottom>
        {/* <div className="top">
          <div className="item">
            <div className="count">60+</div>
            <div className="name">机构用户数量</div>
          </div>
          <div className="item">
            <div className="count">95,060+</div>
            <div className="name">在线学习人数</div>
          </div>
          <div className="item">
            <div className="count">9,780+</div>
            <div className="name">优质资源总数</div>
          </div>
          <div className="item">
            <div className="count">683,790+</div>
            <div className="name">累计学习次数</div>
          </div>
        </div> */}
        <div className='bottom'>
          <div>marketing@xiaozhistar.com</div>
          <div>京ICP备18062414号-1 北京小致教育科技有限公司</div>
          <div>Copyright © 2020 All rights reserved</div>
        </div>
      </BgImgBottom>
    </React.Fragment>
  );
}

const Title = styled.div`
  height: max-content;
  color: #fff;
  background: #0033cc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 45px;
  }
  > .introduce {
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    margin: 10px 0 45px 0;
  }
`;

const Gif = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > .gif-wrapper {
    width: 334px;
    height: 247px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: showgif 3s;
    > .gif {
      width: 354px;
      height: 249px;
    }
  }
  @keyframes showgif {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BgImg = styled.div`
  height: 648px;
  width: 100%;
  background: url(${background_header}) no-repeat bottom;
  background-size: 100% 100%;
  > .header {
    height: 30px;
    width: auto;
    margin: 15px 20px;
  }
`;

const Item = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 30px 0 70px 0;
  > .top {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .title {
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      /* font-weight: bold; */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      > .iconfont {
        font-family: 'iconfont' !important;
        font-size: 20px;
        font-style: normal;
        display: block;
        color: #0033cc;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 10px;
      }
    }
    > .en {
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 25px;
      text-align: center;
    }
    > .content {
      font-size: 14px;
      margin-bottom: 30px;
      padding: 0 20px;
      text-align: center;
    }
  }
  > .bottom {
    /* height: max-content; */
    height: 286px;
    position: relative;
    > .circle {
      height: 286px;
      width: 286px;
      border-radius: 50%;
      background: #0033cc;
    }
    > .img {
      position: absolute;
      width: 330px;
      height: 185px;
      top: 64px;
      left: 37px;
    }
    > .block {
      position: absolute;
      right: 0;
      top: 0;
      height: 286px;
      width: 286px;
      border-radius: 50% 0 0 50%;
      background: #0033cc;
    }
    > .comp2 {
      position: absolute;
      height: 295px;
      width: 293px;
      /* top: 6px; */
      left: 37px;
    }
  }
  > .bottom2 {
    height: max-content;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > .page {
      position: absolute;
      top: 55px;
      width: 320px;
      height: 171px;
    }
    > .comp3 {
      height: 225px;
      width: 295px;
    }
    > .block {
      position: absolute;
      right: 0;
      top: 0;
      height: 286px;
      width: 286px;
      border-radius: 50% 0 0 50%;
      background: #0033cc;
    }
  }
`;

const BgImgBottom = styled.div`
  margin-top: 60px;
  height: 236px;
  width: 100%;
  background: url(${background_footer}) no-repeat bottom;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > .top {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #fff;
    padding-bottom: 25px;
    > .item:last-child {
      > .count {
        border-right: 0;
      }
    }
    > .item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > .count {
        border-right: 1px solid #fff;
        height: 24px;
        line-height: 24px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      > .name {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  > .bottom {
    display: flex;
    opacity: 0.7;
    width: 100%;
    font-size: 12px;
    flex-direction: column;
    background-color: rgba(21, 219, 149, 0.7);
    > div {
      color: #000;
      height: 25px;
      line-height: 25px;
      padding-left: 20px;
    }
  }
`;

const ImgList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .title {
    font-size: 18px;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  > .imgs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    > .img-wrapper {
      margin-right: 20px;
      > .img {
        height: 20px;
      }
    }
  }
`;
